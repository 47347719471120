import React from 'react';
export default () => (
    <article>
      <h1>About Me</h1>
			<p>Hello!  I am Rich Soni, and I am an engineer and a writer of several varieties.</p>
      <p>
        As a software engineer, I have over a decade of professional experience in multiple domains, but most prefer web technology.
        I currently <a href="https://www.linkedin.com/in/richsoni">work at Spotify</a>, where I am focused on building <a href="https://artists.spotify.com/">tooling to empower Music Creators</a>.
        I have also held full time software engineering positions at <a href="https://www.wework.com/">WeWork</a>, <a href="https://ticketevolution.com/">Ticket Evolution</a>, and <a href="https://www.ubs.com/us/en.html">UBS</a>.
        I received a BS in Computer Science from <a href="https://www.stevens.edu/school-engineering-science/departments/computer-science">Stevens Institute of Technology</a> in 2011.
      </p>
      <p>
        As a sound and video engineer, I have recorded, mixed, mastered, and released a multitude of projects.
        This includes <a href="https://richsoni.com/performances">45+ of my own live music performance recordings</a>, <a href="https://open.spotify.com/show/7pHF6WAm6KqTXThJpYlVbp?si=a35598bdc1d644ab">20+ of my podcast episodes</a>, and <a href="https://richsoni.com/discography">8+ music albums</a>, and <a href="https://www.youtube.com/channel/UCWXfX7Q-QSbn61smb9DPN8w/">60+ videos on my Youtube Channel</a>.
        I received a BA in Music &amp; Technology <a href="https://www.stevens.edu/school-engineering-science/departments/computer-science">Stevens Institute of Technology</a> in 2011.
      </p>
      <p>
        I also enjoy writing, and have leveraged it in all of my engineering domains, as well as other places.
        I have written <a href="http://www.richsoni.com/songs">nearly 100 songs</a>, and <a href="http://www.richsoni.com/posts">dozens of blog posts</a> in addition to the extensive technical writing that I do as a software engineer.
      </p>
      <p>
        I love being inspired by others, and incorporating advice and methods into my work.
        My goal is to do the same for those that explore my content.
        I feel that the best art inspires its consumer to create art of their own.
        If I accomplish that for you, then I feel like my work was worth the effort.
      </p>
      <p>
        Thanks for checking it out!  <br />
        ~ Rich Soni
      </p>
    </article>
);